import styled from "styled-components"
import { rgba } from "polished"

import { colors } from "../../styles/jscolors"
import { breakpoints, sizes } from "../../styles/breakpoints"

export const PopupVideo = styled.div`
  z-index: 21474836479;
  position: fixed;
  top: 65px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  height: calc(100vh - 65px);
  background-color: ${rgba(colors.fullblack, 0.8)};
  @media ${breakpoints.lessThan(sizes.phone)} {
    top: 60px;
    height: calc(100vh - 60px);
  }

  .btn-close {
    /*  */
  }

  .youtube-video-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 390px;

    @media ${breakpoints.lessThan(sizes.phone)} {
      max-height: 290px;
    }
  }

  .youtube-video {
    width: 100%;
    height: 100%;
    margin-right: 20px;
    margin-left: 20px;
  }
`
