import * as React from "react"
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { Button } from "../../Button"

import * as S from "./styled"

const SectionPreFooter = () => (
  <S.Section>
    <S.Container>
      <S.SectionHeader>
        <S.Title as="h6">Atendimento em todo o Brasil</S.Title>
        <S.Paragraph>
          Estamos presentes em 4 Estados por meio de 08 unidades, além do
          atendimento virtual em todo o Brasil. Tal capilaridade garante a nossa
          atuação eficiente em todo território nacional.
        </S.Paragraph>
      </S.SectionHeader>
      <S.SectionContent>
        <Button
          as={AniLink}
          to="/unidades"
          cover
          direction="bottom"
          bg="#EAEAEA"
          duration={0.8}
          title="Veja nossas unidades"
          theme="primary"
          size="lg"
          pressOnClick={true}
        >
          Veja nossas unidades
        </Button>
      </S.SectionContent>
    </S.Container>
  </S.Section>
)

export default SectionPreFooter
