import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionBanner from "../components/Section/SectionBanner"
import SectionAbout from "../components/Section/SectionAbout"
import SectionArticles from "../components/Section/SectionArticles"
import SectionContact from "../components/Section/SectionContact"
import SectionPreFooter from "../components/Section/SectionPreFooter"
import WppButtonFloat from "../components/Button/WppButtonFloat"
import PopupVideo from "../components/PopupVideo"

const IndexPage = () => {
  const data = useStaticQuery(query)

  const [showPopupVideo, setShowPopupVideo] = useState(true)

  const handleCloseVideo = () => setShowPopupVideo(false)

  return (
    <Layout>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="sr6s628dimia0pq3p6gb08aeiy5weg"
        />
      </Helmet>
      <Seo title="Home" />
      <SectionBanner data={data.strapiHomepage.slider} />
      <SectionAbout />
      <SectionArticles data={data.allStrapiArticle.edges} />
      <SectionContact />
      <SectionPreFooter />
      <WppButtonFloat />
      {showPopupVideo && (
        <PopupVideo videoId="E3DW_tTdtL8" handleCloseVideo={handleCloseVideo} />
      )}
    </Layout>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      slider {
        bannerDesktop {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
        }
        bannerMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
        }
        url
      }
    }
    allStrapiArticle(
      filter: { status: { eq: "published" } }
      limit: 5
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          description
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
