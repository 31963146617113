import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import CardAbout from "../../Card/About"

import * as S from "./styled"

const SectionAbout = () => (
  <S.Section>
    <Container>
      <SectionHeader className="s-header" centered maxWidth="800px">
        <SectionTitle as="h3">Ruy Molina Advocacia</SectionTitle>
        {/* <Paragraph>
          Nós somos uma empresa com mais de 15 anos de atuação. Contamos com
          sistema operacional moderno, informatizado, departamentos específicos
          e atendimento personalizado, visando sempre excelência no serviço
          prestado. O foco é garantir que todos tenham acesso à justiça.
          Buscando sempre por inovação e na luta incansável para garantir que
          todos tenham acesso à justiça.
        </Paragraph>
        <Button
          className="btn"
          as={AniLink}
          theme="secondary"
          to="/sobre"
          cover
          direction="bottom"
          bg="#EAEAEA"
          duration={0.8}
          title="Saiba mais"
        >
          Saiba mais
        </Button> */}
      </SectionHeader>

      <SectionContent className="s-content">
        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img1.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Escritório Virtual</h4>
            <p className="paragraph">
              Tenha o escritório RM Advocacia na palma da sua mão. Entre agora
              em contato com nosso departamento jurídico.
            </p>
            <span className="link">
              Cadastre um processo <span className="icon">&#8250;</span>
            </span>
          </div>
          <AniLink
            className="link-full"
            to="/cadastre-um-processo"
            cover
            direction="right"
            bg="#EAEAEA"
            duration={0.8}
            title="Cadastre um processo"
          >
            <span className="sr-only">Cadastre um processo</span>
          </AniLink>
        </CardAbout>

        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img2.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Sobre a RM Advocacia</h4>
            <p className="paragraph">
              Nós somos uma empresa com quase duas décadas de atuação no cenário
              jurídico. Contamos com sistema operacional moderno, informatizado,
              departamentos específicos e atendimento personalizado, visando
              sempre excelência no serviço prestado. Com o propósito de garantir
              o amplo acesso à justiça, principalmente dos grupos socialmente
              vulneráveis.
            </p>
            <span className="link">
              Continue lendo <span className="icon">&#8250;</span>
            </span>
          </div>
          <AniLink
            className="link-full"
            to="/sobre"
            cover
            direction="right"
            bg="#EAEAEA"
            duration={0.8}
            title="Continue lendo"
          >
            <span className="sr-only">Continue lendo</span>
          </AniLink>
        </CardAbout>

        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img3.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Unidades</h4>
            <p className="paragraph">
              Encontre a unidade mais próxima ou o atendimento mais cômodo para
              você.
            </p>
            <span className="link">
              Veja todas as unidades <span className="icon">&#8250;</span>
            </span>
          </div>
          <AniLink
            className="link-full"
            to="/unidades"
            cover
            direction="bottom"
            bg="#EAEAEA"
            duration={0.8}
            title="Veja todas as unidades"
          >
            <span className="sr-only">Veja todas as unidades</span>
          </AniLink>
        </CardAbout>

        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img4.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Áreas de Atuação</h4>
            <p className="paragraph">
              Contamos com soluções jurídicas para as mais diversas áreas e com
              equipe qualificada e para proporcionar a melhor solução, inclusive
              na mediação de conflitos.
            </p>
            <span className="link">
              Saiba mais <span className="icon">&#8250;</span>
            </span>
          </div>
          <AniLink
            className="link-full"
            to="/sobre#areas-de-atuacao"
            cover
            direction="right"
            bg="#EAEAEA"
            duration={0.8}
            title="Saiba mais"
          >
            <span className="sr-only">Saiba mais</span>
          </AniLink>
        </CardAbout>

        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img5.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Modelo de Negócio</h4>
            <p className="paragraph">
              Conheça como funciona o conceito de advocacia acessível, com
              atendimento inovador, sustentável, que está ganhando adeptos por
              todo o país. Venha fazer parte desse projeto inovador.
            </p>
            <span className="link">
              Continue lendo <span className="icon">&#8250;</span>
            </span>
          </div>
          <AniLink
            className="link-full"
            to="/modelo-de-negocio"
            cover
            direction="bottom"
            bg="#EAEAEA"
            duration={0.8}
            title="Continue lendo"
          >
            <span className="sr-only">Continue lendo</span>
          </AniLink>
        </CardAbout>

        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img6.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Engajamento Social</h4>
            <p className="paragraph">
              Temos o objetivo de contribuir com o desenvolvimento social em
              todo o Brasil. Proporcionamos diversas atividades socias sem fins
              lucrativos em todo território nacional. Faça parte desse movimento
              agora!
            </p>
            <span className="link">
              Continue lendo <span className="icon">&#8250;</span>
            </span>
          </div>
          <AniLink
            className="link-full"
            to="/engajamento-social"
            cover
            direction="bottom"
            bg="#EAEAEA"
            duration={0.8}
            title="Continue lendo"
          >
            <span className="sr-only">Continue lendo</span>
          </AniLink>
        </CardAbout>

        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img7.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Faça parte da nossa Equipe</h4>
            <p className="paragraph">
              Tenha atitude! Cadastre-se agora para fazer parte da nossa equipe.
            </p>
            <span className="link">
              Envie sua candidatura <span className="icon">&#8250;</span>
            </span>
          </div>
          <AniLink
            className="link-full"
            to="/trabalhe-conosco"
            cover
            direction="right"
            bg="#EAEAEA"
            duration={0.8}
            title="Envie sua candidatura"
          >
            <span className="sr-only">Envie sua candidatura</span>
          </AniLink>
        </CardAbout>

        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img8.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Sala de Imprensa</h4>
            <p className="paragraph">
              Nos contate, siga nossas redes sociais e baixe o app para ficar
              sempre atualizado.
            </p>
            <span className="link">
              Entre em contato <span className="icon">&#8250;</span>
            </span>
          </div>
          <AniLink
            className="link-full"
            to="/contato"
            cover
            direction="right"
            bg="#EAEAEA"
            duration={0.8}
            title="Entre em contato"
          >
            <span className="sr-only">Entre em contato</span>
          </AniLink>
        </CardAbout>
        <CardAbout className="card">
          <figure className="c-figure">
            <StaticImage
              className="img-wrapper"
              imgClassName="img"
              src="../../../images/cards-about/img9.png"
              objectFit="cover"
              objectPosition="center"
              placeholder="blurred"
              alt="Escritório Virtual"
            />
          </figure>
          <div className="c-content">
            <h4 className="title">Consulta Processual</h4>
            <p className="paragraph">Consulte agora o seu processo</p>
            <span className="link">
              Consultar Processos <span className="icon">&#8250;</span>
            </span>
          </div>
          <a
            className="link-full"
            href="https://linktr.ee/RuyMolinaAdvocacia"
            target="_blank"
            rel="noreferrer"
            title="Continue lendo"
          >
            <span className="sr-only">Consultar processos</span>
          </a>
        </CardAbout>
      </SectionContent>
    </Container>
  </S.Section>
)

export default SectionAbout
