import styled from "styled-components"
import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Card = styled.article`
  position: relative;
  background-color: var(--color-white);
  border-radius: 4px;
  overflow: hidden;

  .c-figure {
    overflow: hidden;
    width: 100%;
    height: 130px;

    .img-wrapper {
      width: 100%;
      height: 100%;
      transition: transform ease-in-out 0.4s;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .c-content {
    padding: 20px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${breakpoints.lessThan(sizes.phone)} {
      height: auto;
    }

    .title {
      font-weight: bold;
      color: var(--color-primary);
      font-size: 1.2rem;
    }

    .paragraph {
      margin-top: 12px;
      margin-bottom: auto;
      color: var(--color-grayscale5);
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .link {
      font-size: 0.8rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      color: var(--color-primary);
      text-transform: uppercase;
      transition: transform ease-in-out 0.2s, color linear 0.2s;
      @media ${breakpoints.lessThan(sizes.phone)} {
        margin-top: 15px;
        margin-bottom: 0;
      }

      .icon {
        margin-left: 5px;
        font-size: 1.5rem;
        padding-bottom: 2px;
      }
    }
  }

  .link-full {
    z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover {
    /* .c-figure .img-wrapper {
      transform: scale(1.08);
    } */

    .c-content {
      .link {
        transform: translateX(10px);
        color: var(--color-secondary);
      }
    }
  }
`
