import * as React from "react"
import YouTube from "react-youtube"

import { Button } from "../Button"

import { CloseCircle as CloseIcon } from "@styled-icons/evaicons-solid/CloseCircle"

import * as S from "./styled"

const PopupVideo = props => {
  const { videoId, handleCloseVideo } = props

  const opts = {
    // height: "280",
    // width: "320",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // controls: 0,
      disablekb: 1,
      autoplay: 1,
      iv_load_policy: 3,
      modestbranding: 1,
      rel: 0,
    },
  }

  const onReady = event => {
    // access to player in all event handlers via event.target
    event.target.playVideo()
  }

  return (
    <S.PopupVideo>
      <YouTube
        containerClassName="youtube-video-wrapper"
        className="youtube-video"
        videoId={videoId}
        opts={opts}
        onReady={onReady}
      />
      <Button
        classsName="btn-close"
        type="button"
        hasIcon
        size="xs"
        theme="white"
        onClick={handleCloseVideo}
      >
        Fechar vídeo
        <CloseIcon className="icon icon-after" />
      </Button>
    </S.PopupVideo>
  )
}

export default PopupVideo
