import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"

import { Section as SectionComponent } from "../"

export const Section = styled(SectionComponent)`
  padding: 50px 0;
  background-color: var(--color-white);
`
export const WrapperPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .card {
    width: calc(33.333% - 20px);
    @media ${breakpoints.lessThan(sizes.desktop)} {
      width: calc(50% - 15px);
    }
    @media ${breakpoints.lessThan(sizes.tablet)} {
      width: 100%;
    }

    &:first-of-type {
      /* flex: 3; */
      width: calc(60% - 15px);
      @media ${breakpoints.lessThan(sizes.desktop)} {
        width: 100%;
      }
    }

    &:nth-of-type(2) {
      /* flex: 2; */
      width: calc(40% - 15px);
      @media ${breakpoints.lessThan(sizes.desktop)} {
        width: calc(50% - 15px);
      }
      @media ${breakpoints.lessThan(sizes.tablet)} {
        width: 100%;
      }
    }
  }

  .btn {
    margin: 15px auto 0;
  }
`
