import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"

import { Section as SectionComponent } from "../../Section"

export const Section = styled(SectionComponent)`
  padding-bottom: 50px;

  .s-header {
    /* margin-bottom: 40px; */
    /* margin-bottom: 60px; */
    .btn {
      margin-top: 20px;
    }
  }

  .s-content {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    .card {
      flex: 1 290px;
      max-width: 350px;
      @media ${breakpoints.lessThan(sizes.widescreen)} {
        flex: 0 290px;
        min-width: 290px;
      }
      @media ${breakpoints.lessThan(sizes.desktop)} {
        flex: 1 256px;
        max-width: unset;
        min-width: unset;
      }
    }
  }
`
