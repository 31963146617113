import * as React from "react"
// import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import { Paragraph } from "../../Text"
import CardArticlePost from "../../Card/ArticlePost"
import { Button } from "../../Button"

import * as S from "./styled"

const SectionArticles = props => {
  const { data } = props

  return (
    <S.Section>
      <Container>
        <SectionHeader centered maxWidth="800px">
          <SectionTitle as="h3">Mantenha-se atualizado</SectionTitle>
          <Paragraph as="h4">
            Conteudos inéditos, atuais e feito por gente que entende.
          </Paragraph>
        </SectionHeader>

        <SectionContent>
          <S.WrapperPosts>
            {data.map(post => (
              <CardArticlePost className="card" key={post.node.id}>
                <figure className="c-figure">
                  {post.node.image ? (
                    <GatsbyImage
                      className="img-wrapper"
                      imgClassName="img"
                      objectFit="cover"
                      objectPosition="center"
                      placeholder="blurred"
                      alt={post.node.title}
                      image={
                        post.node.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                    />
                  ) : (
                    <StaticImage
                      className="img-wrapper"
                      imgClassName="img"
                      src="../../../images/brand.png"
                      objectFit="cover"
                      objectPosition="center"
                      placeholder="blurred"
                      alt="Imagem do artigo"
                    />
                  )}
                </figure>
                <div className="c-content">
                  <h4 className="title">{post.node.title}</h4>
                  <p className="paragraph">{post.node.description}</p>
                  <span className="link">
                    Leia mais <span className="icon">&#8250;</span>
                  </span>
                </div>
                <AniLink
                  className="link-full"
                  to={`/artigos/${post.node.slug}`}
                  cover
                  direction="bottom"
                  bg="#EAEAEA"
                  duration={0.8}
                  title="Leia mais"
                >
                  <span className="sr-only">Leia mais</span>
                </AniLink>
              </CardArticlePost>
            ))}
            <Button
              className="btn"
              as={AniLink}
              theme="secondary"
              to="/artigos"
              cover
              direction="bottom"
              bg="#EAEAEA"
              duration={0.8}
              title="Ver todos os artigos"
            >
              Ver todos os artigos
            </Button>
          </S.WrapperPosts>
        </SectionContent>
      </Container>
    </S.Section>
  )
}

export default SectionArticles
