import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"
import { Container as ContainerComponent } from "../../../styles/container"
import { Section as SectionComponent } from "../index"

import {
  Title as TitleComponent,
  Paragraph as ParagraphComponent,
} from "../../Text"

export const Section = styled(SectionComponent)`
  padding-bottom: 50px;
`

export const Container = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.lessThan(sizes.phone)} {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }
`

export const SectionHeader = styled.header`
  flex: 1;
  max-width: 600px;
`

export const SectionContent = styled.div`
  margin-left: 40px;

  @media ${breakpoints.lessThan(sizes.phone)} {
    margin-left: 0;
    margin-top: 25px;
  }
`

export const Title = styled(TitleComponent)`
  margin-bottom: 10px;
`

export const Paragraph = styled(ParagraphComponent)`
  /* border: 2px solid red; */
`
