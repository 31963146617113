import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"

import { Section as SectionComponent } from "../"

export const Section = styled(SectionComponent)`
  padding-top: 50px;

  .s-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* border: 1px solid red; */

    .card {
      flex: 1 2 300px;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        flex: 1 2 200px;
      }
      @media ${breakpoints.lessThan(sizes.tablet)} {
        flex: unset;
      }
      /* border: 1px solid black; */
    }
  }
`
