import * as React from "react"

import { Container } from "../../../styles/container"
import BannerSlider from "../../BannerSlider"
import CompanyGuidelines from "../../CompanyGuidelines"

import * as S from "./styled"

const SectionBanner = props => {
  const { data } = props

  return (
    <S.Section noPadding>
      <S.SectionHeader>
        <BannerSlider data={data} />
      </S.SectionHeader>

      <S.SectionContent noMargin>
        <Container>
          <CompanyGuidelines className="card-wrapper" />
        </Container>
      </S.SectionContent>
    </S.Section>
  )
}

export default SectionBanner
