import React from "react"
import { withArtDirection } from "gatsby-plugin-image"

import * as S from "./styled"

const ReponsivePicture = props => {
  const { desktopImage, mobileImage, mobileBreakpoint } = props

  let images

  if (desktopImage && mobileImage) {
    images = withArtDirection(desktopImage, [
      {
        media: `(max-width: ${mobileBreakpoint})`,
        image: mobileImage,
      },
    ])
  } else {
    images = desktopImage
  }

  return <S.GatsbyImage {...props} image={images} imgClassName="img" />
}

export default ReponsivePicture
