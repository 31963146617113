import * as React from "react"
import Slider from "react-slick"

import ResponsivePicture from "../ResponsivePicture"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import * as S from "./styled"

export const PrevArrow = props => {
  const { className, onClick } = props
  return (
    <S.PrevArrow className={className} onClick={onClick}>
      <S.LeftArrowIcon />
    </S.PrevArrow>
  )
}

export const NextArrow = props => {
  const { className, onClick } = props
  return (
    <S.NextArrow className={className} onClick={onClick}>
      <S.RightArrowIcon />
    </S.NextArrow>
  )
}

const BannerSlider = props => {
  const banners = props.data

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 800,
    fade: true,
    lazyLoad: false,
    pauseOnHover: true,
    swipe: true,
    swipeToSlide: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  return (
    <S.SliderWrapper>
      <Slider {...settings}>
        {banners.map((banner, index) => (
          <div key={index}>
            <S.SlideItem>
              <a
                className="url-image"
                href={banner.url}
                title="Ver mais"
                target="_blank"
                rel="noreferrer"
              >
                <ResponsivePicture
                  alt="Slider 1"
                  objectFit="cover"
                  objectPosition="center"
                  url={banner.url}
                  desktopImage={
                    banner.bannerDesktop.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  mobileImage={
                    banner.bannerMobile.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  mobileBreakpoint="768px"
                />
              </a>
            </S.SlideItem>
          </div>
        ))}
      </Slider>
    </S.SliderWrapper>
  )
}

export default BannerSlider
