import styled from "styled-components"
import { rgba } from "polished"

import { breakpoints, sizes } from "../../../styles/breakpoints"
import { colors } from "../../../styles/jscolors"

import {
  Section as SectionComponent,
  SectionHeader as SectionHeaderComponent,
  SectionContent as SectionContentComponent,
} from "../"

export const Section = styled(SectionComponent)`
  /* overflow: hidden; */
  /* border: 2px solid red; */
`

export const SectionHeader = styled(SectionHeaderComponent)`
  /* background-color: red; */
`

export const SectionContent = styled(SectionContentComponent)`
  /* border: 2px solid yellow; */

  .card-wrapper {
    margin-top: -50px;

    @media ${breakpoints.lessThan(sizes.widescreen)} {
      gap: 20px;
    }

    @media ${breakpoints.lessThan(sizes.desktop)} {
      margin-top: 40px;

      .card.-guideline {
        flex: unset;
        width: 100%;
      }
    }

    @media ${breakpoints.greaterThan(sizes.desktop)} {
      .card.-guideline {
        box-shadow: 0 0px 15px ${rgba(colors.black, 0.35)};
        ::before {
          display: none;
        }
      }
    }
  }
`
