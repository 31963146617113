import styled from "styled-components"

import { IosArrowLtr as LeftArrow } from "@styled-icons/fluentui-system-filled/IosArrowLtr"
import { IosArrowRtl as RightArrow } from "@styled-icons/fluentui-system-filled/IosArrowRtl"

import { breakpoints, sizes } from "../../styles/breakpoints"

export const SliderWrapper = styled.div`
  /* border: 2px solid red; */
  /* background-color: red; */
`

export const PrevArrow = styled.button`
  z-index: 100;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40px;
  @media ${breakpoints.lessThan(sizes.tablet)} {
    width: 55px;
    height: 55px;
    left: 25px;
  }
  @media ${breakpoints.lessThan(sizes.phone)} {
    left: 5px;
  }

  &::before {
    display: none;
  }
`

export const NextArrow = styled.button`
  z-index: 100;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 40px;
  @media ${breakpoints.lessThan(sizes.tablet)} {
    width: 55px;
    height: 55px;
    right: 25px;
  }
  @media ${breakpoints.lessThan(sizes.phone)} {
    right: 5px;
  }

  &::before {
    display: none;
  }
`

export const LeftArrowIcon = styled(LeftArrow)`
  width: 45px;
  height: 45px;
  color: var(--color-white);
  transition: all ease 0.2s;
  transform-origin: center;
  @media ${breakpoints.lessThan(sizes.tablet)} {
    width: 35px;
    height: 35px;
  }

  &:hover {
    opacity: 0.7;
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1.1);
  }
`

export const RightArrowIcon = styled(RightArrow)`
  width: 45px;
  height: 45px;
  color: var(--color-white);
  transition: all ease 0.2s;
  transform-origin: center;
  @media ${breakpoints.lessThan(sizes.tablet)} {
    width: 35px;
    height: 35px;
  }

  &:hover {
    opacity: 0.7;
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1.1);
  }
`

export const SlideItem = styled.div`
  position: relative;
  min-height: 500px;
  height: calc(100vh - 150px);
  width: 100%;
  background-color: var(--color-primary);
  @media ${breakpoints.lessThan(sizes.phone)} {
    min-height: 420px;
    height: calc(100vh - 60px);
  }

  .url-image {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .c-img {
    position: relative;
    width: 100%;
    height: 100%;
  }
`
