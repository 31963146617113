import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Card = styled.div`
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 2px;
  transition: background-color linear 0.2s;
  @media ${breakpoints.lessThan(sizes.desktop)} {
    height: 370px;
  }
  @media ${breakpoints.lessThan(sizes.tablet)} {
    height: auto;
    padding: 30px 20px;
  }

  .icon {
    margin-bottom: 12px;
    color: var(--color-secondary);
    width: 100px;
    height: 100px;
    @media ${breakpoints.lessThan(sizes.desktop)} {
      height: 80px;
      width: 80px;
    }
  }

  .value {
    margin-bottom: 25px;
    transition: color linear 0.2s;
    @media ${breakpoints.lessThan(sizes.desktop)} {
      font-size: 0.9rem;
    }
  }

  .title {
    font-size: 1.2rem;
    margin-bottom: 16px;
    transition: color linear 0.2s;
    @media ${breakpoints.lessThan(sizes.desktop)} {
      font-size: 1.1rem;
    }
  }

  .btn {
    background-color: transparent;
    border-color: var(--color-secondary);
    color: var(--color-secondary);
    @media ${breakpoints.lessThan(sizes.desktop)} {
      font-size: 0.9rem;
    }
  }

  .link-full {
    z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-primary);

    .title,
    .value {
      color: var(--color-white);
    }
  }
`
