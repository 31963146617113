import * as React from "react"

import { Phone as IconPhone } from "@styled-icons/boxicons-regular/Phone"
import { Whatsapp as IconWhatsapp } from "@styled-icons/boxicons-logos/Whatsapp"
import { EmailOutline as IconEmail } from "@styled-icons/evaicons-outline/EmailOutline"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import { Title } from "../../Text"
import CardContact from "../../Card/Contact"
import { Button } from "../../Button"

import * as S from "./styled"

const SectionContact = () => (
  <S.Section>
    <Container>
      <SectionHeader centered maxWidth="800px">
        <SectionTitle as="h4">Queremos falar com você</SectionTitle>
      </SectionHeader>

      <SectionContent className="s-content">
        <CardContact className="card">
          <IconWhatsapp className="icon" />
          <div className="value">0800 252 1600</div>
          <Title className="title" as="h5">
            Whatsapp
          </Title>
          <Button className="btn" as="span" long={true}>
            Envie uma mensagem
          </Button>
          <a
            className="link-full"
            href="https://api.whatsapp.com/send?phone=5508002521600&abid=5508002521600"
            target="_blank"
            rel="noreferrer"
            title="Envie uma mensagem"
          >
            <span className="sr-only">Envie uma mensagem</span>
          </a>
        </CardContact>

        <CardContact className="card">
          <IconEmail className="icon" />
          <div className="value">contato@ruymolina.adv.br</div>
          <Title className="title" as="h5">
            Email
          </Title>
          <Button className="btn" as="span" long={true}>
            Envie um e-mail
          </Button>
          <a
            className="link-full"
            href="mailto:contato@ruymolina.adv.br"
            target="_blank"
            rel="noreferrer"
            title="Envie uma mensagem"
          >
            <span className="sr-only">Envie uma mensagem</span>
          </a>
        </CardContact>

        <CardContact className="card">
          <IconPhone className="icon" />
          <div className="value">0800 252 1600</div>
          <Title className="title" as="h5">
            Central Telefonica
          </Title>
          <Button className="btn" as="span" long={true}>
            Ligue agora
          </Button>
          <a
            className="link-full"
            href="tel:08002521600"
            target="_blank"
            rel="noreferrer"
            title="Ligue agora"
          >
            <span className="sr-only">Ligue agora</span>
          </a>
        </CardContact>
      </SectionContent>
    </Container>
  </S.Section>
)

export default SectionContact
