import styled from "styled-components"
import { GatsbyImage as GatsbyImageComponent } from "gatsby-plugin-image"

export const GatsbyImage = styled(GatsbyImageComponent)`
  width: 100%;
  height: 100%;

  .img {
    width: 100%;
    height: 100%;
  }
`
